import React from "react";
import { INNER_CHOOSE_IMG } from "../../lib/assets";

export const ChooseAreaThree = () => {
  return (
    <section id="context" className="choose-area-three">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="choose-content-three">
              <div className="section-title-two white-title mb-30">
                <span className="sub-title">Contexual FAQs</span>
                <h2 className="title">
                  Additional Terminology & Requirements Information
                </h2>
              </div>
              <p>
                The Nomadic Platform requires contextual information regarding your personal status, business type and income level to function.
              </p>

              <div className="accordion-wrap-two">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        What exactly is a U.S. "Person"?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          Nomadic is designed for U.S. "Persons", generally this refers to U.S. Tax Residents or U.S. Citizens living abroad. It may include - but is not limited to - the following:<br/>
                          <br/>
                          • US Citizens (Domestic)<br/>
                          • US Citizens (Overseas)<br/>
                          • US Green Card Holders<br/>
                          • US Born Non-Residents<br/>
                          • US Business Owners<br/>
                          • US Permanent Residents<br/>
                          • US Temporary Residents<br/>
                          • US Tax Residents (Any)<br/>
                          <br/>
                          You don't need U.S. Citizenship to use Nomadic, but you cannot use the platform if you have had U.S. Citizenship and then renounced it. Although, if you have, please get in touch with our team, as we may have alternative solutions for you.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Who can use the Nomadic platform?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          Nomadic is designed for individuals and businesses earning from an *active* trade. If your main source of income is passive investments, please get in touch as you won't be able to use the platform and we can provide alternative solutions.
                          <br/><br/>
                          • Freelancers<br/>
                          • Digital Nomads<br/>
                          • Remote Workers<br/>
                          • Entrepreneurs<br/>
                          • Content Creators<br/>
                          • Business Owners<br/>
                          • Ticket Brokers<br/>
                          • Video Streamers<br/>
                          • Ecommerce Stores<br/>
                          • SaaS Businesses<br/>
                          • Active Investors
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        How much do I need to be making?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          Nomadic is designed for profits between $65,000 and $999,999, this could either be through self employment or a legal business structure. Unfortunately we can't help if you're making less than that, but if you're making more our specialized team can help on a case-by-case basis.
                        </p>
                      </div>
                    </div>
                  </div>
                  
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        What about other nationalities?
                        
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          Whilst Nomadic is designed for U.S. "Persons" and U.K. Tax Residents, if you're not one, Nomadic may still work for you depending on your tax residency, individual circumstances and (in some cases) your nationality. Please get in touch for clarification on your situation and to see if Nomadic Tax can work for you. Our expat and nomad version is coming soon.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="choose-img-three">
              <img src={INNER_CHOOSE_IMG} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
