import React from "react";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";

export const NavMenus = () => {
  const { pathname } = useLocation();

  const isActiveCn = (path) => {
    return path === pathname ? "active" : "";
  };

  const homeLinkList = [
    {
      to: "#features",
      title: "Features",
    },
    {
      to: "#about",
      title: "About",
    },
    {
      to: "#process",
      title: "Process",
    },
    {
      to: "#results",
      title: "Results",
    },
  ];

  const usesLinkList = [
    {
      to: "/business-owners",
      title: "Business Owners",
    },
    {
      to: "/content-creators",
      title: "Content Creators",
    },
    {
      to: "/freelancers",
      title: "Freelance Workers",
    },
    {
      to: "/investors",
      title: "Active Investors",
    },
  ];

  const servicesLinkList = [
    {
      to: "/services",
      title: "Digital Nomad Visas",
    },
    {
      to: "/services-2",
      title: "Medical Tourism",
    },
    {
      to: "/services-3",
      title: "Real Estate Tour",
    },
    {
      to: "/services-4",
      title: "Services Four",
    },
    {
      to: "/services-5",
      title: "Services Five",
    },
  ];

  const servicesDetailsLinkList = [
    {
      to: "/services-details",
      title: "Services Details One",
    },
    {
      to: "/services-details-2",
      title: "Services Details Two",
    },
    {
      to: "/services-details-3",
      title: "Services Details Three",
    },
    {
      to: "/services-details-4",
      title: "Services Details Four",
    },
    {
      to: "/services-details-5",
      title: "Services Details Five",
    },
  ];

  return (
    <div className="navbar-wrap main-menu d-none d-lg-flex">
      <ul className="navigation">
        {/* home */}
        <li
          className={cn("menu-item-has-children", {
            active: homeLinkList.map((el) => el.to).includes(pathname),
          })}
        >
          <a href="#">Platform</a>
          <ul className="sub-menu">
            {homeLinkList.map((el) => (
              <li key={el.to} className={cn(isActiveCn(el.to))}>
                <a href={el.to}>{el.title}</a>
              </li>
            ))}
          </ul>
        </li>

        {/* about */}
        <li
          className={cn("menu-item-has-children", {
            active: usesLinkList.map((el) => el.to).includes(pathname),
          })}
        >
          <a href="#use-cases">Use Cases</a>
          <ul className="sub-menu">
            {usesLinkList.map((el) => (
              <li key={el.to} className={cn(isActiveCn(el.to))}>
                <a href={el.to}>{el.title}</a>
              </li>
            ))}
          </ul>
        </li>
        
        {/* blog */}
        <li
          className={cn("menu-item-has-children", {
            active: ["/blog", "/blog-details"].includes(pathname),
          })}
        >
          <a href="#packages">Packages</a>
          <ul className="sub-menu">
            <li className={cn(isActiveCn("/blog"))}>
              <a href="#packages">Freelancer</a>
            </li>
            <li className={cn(isActiveCn("/blog-details"))}>
              <a href="#packages">Entreprenuer</a>
            </li>
            <li className={cn(isActiveCn("/blog-details"))}>
              <a href="#packages">Startup Plan</a>
            </li>
            <li className={cn(isActiveCn("/blog-details"))}>
              <a href="#packages">Business Plan</a>
            </li>
          </ul>
        </li>
        
        
        {/* blog */}
        <li
          className={"menu-item-has-children"}
        >
          <a href="#context">Versions</a>
          <ul className="sub-menu">
            <li className={cn(isActiveCn("/us"))}>
              <a href="/us">United States 🇺🇸</a>
            </li>
            <li className={cn(isActiveCn("/uk"))}>
              <a href="/uk">United Kingdom 🇬🇧</a>
            </li>
            <li className={cn(isActiveCn("/expats"))}>
              <a href="#coming-soon">Nomads & Expats</a>
            </li>
          </ul>
        </li>

        {/* contact */}
        {/* <li className={cn(isActiveCn("#contact"))}>
          <a href="#contact">Contact</a>
        </li> */}
      </ul>
    </div>
  );
};
