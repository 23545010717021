import React from "react";
import {
  FAQ_IMG01,
  FAQ_IMG02,
  FAQ_SHAPE02,
  FAQ_SHAPE03,
} from "../../lib/assets";

export const FaqAreaOne = () => {
  return (
    <section id="faqs" className="faq-area">
      <div className="faq-bg-shape"></div>

      <div className="faq-shape-wrap">
        <img src={FAQ_SHAPE02} alt="" />
        <img src={FAQ_SHAPE03} alt="" />
      </div>

      <div className="container">
        <div className="row align-items-end justify-content-center">
          <div className="col-lg-6 col-md-9">
            <div className="faq-img-wrap">
              <img src={FAQ_IMG01} alt="" />
              <img src={FAQ_IMG02} alt="" data-parallax='{"y" : 100 }' />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="faq-content">
              <div className="section-title mb-30 tg-heading-subheading animation-style2">
                <span className="sub-title tg-element-title">
                  Contexual FAQs
                </span>
                <h2 className="title tg-element-title">
                  Additional Terminology & Requirements Information
                </h2>
              </div>
              <p>
                The Nomadic Platform requires contextual information regarding your personal status, business type and income level to function.
              </p>

              <div className="accordion-wrap">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        What exactly is a U.S. "Person"?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          Nomadic is designed for U.S. "Persons", generally this refers to U.S. Tax Residents or U.S. Citizens living abroad. It may include - but is not limited to - the following:<br/>
                          <br/>
                          US Citizens (Domestic)<br/>
                          US Citizens (Overseas)<br/>
                          US Green Card Holders<br/>
                          US Born Non-Residents<br/>
                          US Business Owners<br/>
                          US Permanent Residents<br/>
                          US Temporary Residents<br/>
                          US Tax Residents (Any)<br/>
                          <br/>
                          You don't need U.S. Citizenship to use Nomadic, but you cannot use the platform if you have had U.S. Citizenship and then renounced it. Although, if you have, please get in touch with our team, as we may have alternative solutions for you.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Who can use the Nomadic platform?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          Nomadic is designed for individuals and businesses earning from an *active* trade. If your main source of income is passive investments, please get in touch as you won't be able to use the platform and we can provide alternative solutions.
                          <br/><br/>
                          Freelancers<br/>
                          Digital Nomads<br/>
                          Remote Workers<br/>
                          Entrepreneurs<br/>
                          Content Creators<br/>
                          Business Owners<br/>
                          Video Streamers<br/>
                          Ecommerce Stores<br/>
                          SaaS Businesses<br/>
                          Active Investors
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        How much do I need to be making?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          Nomadic is designed for profits between $65,000 and $999,999, this could either be through self employment or a legal business structure. Unfortunately we can't help if you're making less than that, but if you're making more our specialized team can help on a case-by-case basis.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        What if I am not a U.S. "Person"?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          Nomadic is designed for profits between $65,000 and $999,999, this could either be through self employment or a legal business structure. Unfortunately we can't help if you're making less than that, but if you're making more our specialized team can help on a case-by-case basis.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
