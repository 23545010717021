const PRICING_PLANS = [
  "5000 User Activities",
  "Unlimited Access",
  "No Hidden Charge",
  "03 Time Updates",
  "Figma Source File",
  "Many More Facilities",
];

const PRICING_PLAN_FRELANCER = [
  "Full Legal Compliance",
  "Automated Tax Filings",
  "Monthly Distributions",
  "No Business Tax Rate",
];

const PRICING_PLAN_ENTREPRENUER = [
  "Full Legal Compliance",
  "Automated Tax Filings",
  "Regular Distributions",
  "Multi-Currency Banking",
  "Credit Card Processing",
  "~10% Business Tax Rate",
];

const PRICING_PLAN_ENTREPRENUER_UK = [
  "Full Legal Compliance",
  "Automated Tax Filings",
  "Regular Distributions",
  "Multi-Currency Banking",
  "Credit Card Processing",
  "0% Business Tax Rate",
];

const PRICING_PLAN_STARTUP = [
  "Full Legal Compliance",
  "Automated Tax Filings",
  "Anytime Distributions",
  "Multi-Currency Banking",
  "Credit Card Processing",
  "0% Business Tax Rate",
  "Our Collection Agent",
];

const PRICING_PLAN_BUSINESS = [
  "Full Legal Compliance",
  "Automated Tax Filings",
  "Anytime Distributions",
  "Multi-Currency Banking",
  "Credit Card Processing",
  "0% Business Tax Rate",
  "Nominee Shareholder",
  "Controlling Access",
];

export const PRICING_LIST_TWO = [
  {
    id: 1,
    isPopular: false,
    title: "Basic Plan",
    prices: { nomadic: 19, branded: 119 },
    setup: { nomadic: 999, branded: 499 },
    plans: PRICING_PLANS,
    href: "/contact",
  },
  {
    id: 2,
    isPopular: true,
    title: "Team Plan",
    prices: { nomadic: 49, branded: 499 },
    setup: { nomadic: 999, branded: 499 },
    plans: PRICING_PLANS,
    href: "/contact",
  },
  {
    id: 3,
    isPopular: false,
    title: "Enterprise Plan",
    prices: { nomadic: 99, branded: 999 },
    setup: { nomadic: 999, branded: 499 },
    plans: PRICING_PLANS,
    href: "/contact",
  },
];

export const PRICING_LIST_THREE = [
  {
    id: 1,
    isPopular: false,
    title: { nomadic: "Freelancer", branded: "Entreprenuer" },
    prices: { nomadic: 249, branded: 499 },
    setup: { nomadic: 2999, branded: 4999 },
    plans: { nomadic: PRICING_PLAN_FRELANCER, branded: PRICING_PLAN_ENTREPRENUER },
    href: "/contact",
    icon: { nomadic: "flaticon-inspiration", branded: "flaticon-family" }
  },
  {
    id: 2,
    isPopular: true,
    title: { nomadic: "Startup", branded: "Business" },
    prices: { nomadic: 749, branded: 999 },
    setup: { nomadic: 6999, branded: 8999 },
    plans: { nomadic: PRICING_PLAN_STARTUP, branded: PRICING_PLAN_BUSINESS },
    href: "/contact",
    icon: { nomadic: "flaticon-rocket", branded: "flaticon-briefcase" }
  }
];

export const PRICING_LIST_UK = [
  {
    id: 1,
    isPopular: false,
    title: { nomadic: "Freelancer", branded: "Entreprenuer" },
    prices: { nomadic: 249, branded: 499 },
    setup: { nomadic: 2999, branded: 4499 },
    plans: { nomadic: PRICING_PLAN_FRELANCER, branded: PRICING_PLAN_ENTREPRENUER_UK },
    href: "/contact",
    icon: { nomadic: "flaticon-inspiration", branded: "flaticon-family" }
  },
  {
    id: 2,
    isPopular: true,
    title: { nomadic: "Startup", branded: "Business" },
    prices: { nomadic: 749, branded: 999 },
    setup: { nomadic: 6999, branded: 8999 },
    plans: { nomadic: PRICING_PLAN_STARTUP, branded: PRICING_PLAN_BUSINESS },
    href: "/contact",
    icon: { nomadic: "flaticon-rocket", branded: "flaticon-briefcase" }
  }
];

export const PRICING_LIST_ONE = [
  {
    id: 1,
    isPopular: false,
    title: "Basic Plan",
    prices: { nomadic: 19, branded: 119 },
    setup: { nomadic: 999, branded: 499 },
    plans: PRICING_PLANS,
    href: "/contact",
  },
  {
    id: 2,
    isPopular: true,
    title: "Standard Plan",
    prices: { nomadic: 39, branded: 329 },
    setup: { nomadic: 999, branded: 499 },
    plans: PRICING_PLANS,
    href: "/contact",
  },
  {
    id: 3,
    isPopular: false,
    title: "Business Plan",
    prices: { nomadic: 99, branded: 899 },
    setup: { nomadic: 999, branded: 499 },
    plans: PRICING_PLANS,
    href: "/contact",
  },
];
