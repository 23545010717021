import React from "react";
import { Link } from "react-router-dom";
import { LOGO, W_LOGO } from "../../lib/assets";

export const FooterUK = () => {
  return (
    <footer>
      <div className="footer-area footer-bg">
        <div className="container">
          <div className="footer-top">
            <div className="row">
              <div className="col-lg-3 col-md-7">
                <div className="footer-widget">
                  <h4 className="fw-title">Information</h4>
                  <div className="footer-info">
                    <ul className="list-wrap">
                      <li>
                        <div className="icon">
                          <i className="flaticon-pin"></i>
                        </div>
                        <div className="content">
                          <p>Singapore Office</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="flaticon-phone-call"></i>
                        </div>
                        <div className="content">
                          <a href="tel:+442032896883">020-3289-6883</a>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="flaticon-clock"></i>
                        </div>
                        <div className="content">
                          <p>
                            9am – 6pm <span>(GMT)</span>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-5 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Navigation</h4>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <li>
                        <a href="#">Homepage</a>
                      </li>
                      <li>
                        <a href="#platform">Platform</a>
                      </li>
                      <li>
                        <a href="#use-cases">Use Cases</a>
                      </li>
                      <li>
                        <a href="#packages">Packages</a>
                      </li>
                      <li>
                        <a href="#faqs">Contact</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-5 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Our Solutions</h4>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <li>
                        <a target="_blank" href="https://nomadicx.com/digital-nomad-visas/">Digital Nomad Visas</a>
                      </li>
                      <li>
                        <a target="_blank" href="https://nomadicx.com/real-estate-discovery/">Real Estate Discovery</a>
                      </li>
                      <li>
                        <a target="_blank" href="https://nomadicx.com/global-bank-accounts/">Global Bank Accounts</a>
                      </li>
                      <li>
                        <a target="_blank" href="https://nomadicx.com/heritage-citizenship/">Heritage Citizenship</a>
                      </li>
                      <li>
                        <a target="_blank" href="https://nomadicx.com/offshore-companies/">Offshore Companies</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-7">
                <div className="footer-widget">
                  <h4 className="fw-title">Subscribe to Our Newsletter</h4>
                  <div className="footer-newsletter">
                    <p>
                      Get weekly updates on everything Nomadic.
                    </p>
                    <form action="#">
                      <input type="email" placeholder="enter your e-mail" />
                      <button type="submit">Subscribe</button>
                    </form>
                    <span>We won’t send you any spam.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="left-sider">
                  <div className="f-logo">
                    <a href="https://nomadicx.com" target="_blank">
                      <img src={W_LOGO} alt="Nomadic Logo White" height="40" />
                    </a>
                  </div>
                  <div className="copyright-text">
                    <p>Copyright © {new Date().getFullYear()} Nomadic Group. All Rights Reserved.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="footer-social">
                  <ul className="list-wrap">
                    <li>
                      <a target="_blank" href="https://www.instagram.com/nomadictax/">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.instagram.com/nomadictax/">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.instagram.com/nomadictax/">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.linkedin.com/showcase/nomadictax">
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.linkedin.com/showcase/nomadictax">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
